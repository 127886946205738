<template>
  <div class="event-details" v-if="pageLoaded">

    <template v-if="!isExternal">
      <!-- Header: Administrative Info -->
      <div class="d-flex my-1">
        <feather-icon icon="CalendarIcon" size="19"/>
        <h4 class="mb-0 ml-50">
          {{ msg('Details') }}
        </h4>
      </div>
      <hr/>
      <b-row class="">
        <b-col cols="1" v-b-tooltip.hover :title="msg('Start')">
          <i class="far fa-clock"></i>
        </b-col>
        <b-col cols="5">
          <p class="event-details-description capitalize">{{ event.start }}</p>
        </b-col>
        <b-col cols="1" v-b-tooltip.hover :title="msg('End')">
          <i class="fas fa-clock"></i>
        </b-col>
        <b-col cols="5">
          <p class="event-details-description capitalize">{{ event.end }}</p>
        </b-col>
        <b-col cols="1" v-b-tooltip.hover :title="msg('Host')">
          <i class="far fa-user"></i>
        </b-col>
        <b-col cols="5">
          <p class="event-details-description capitalize">{{ getUserFullName(event.userid) }}</p>
        </b-col>
        <b-col cols="1" >
          <i class="far fa-calendar"></i>
        </b-col>
        <b-col cols="5">
          <p class="event-details-description capitalize">{{ event.typeName }}</p>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <template v-if="event.location != null && event.location.trim().length > 0">
          <b-col cols="1" v-b-tooltip.hover :title="msg('Location')">
            <i class="fas fa-map-marker-alt"></i>
          </b-col>
          <b-col :cols=" event.url != null && event.url.trim().length > 0 ? 5 : 10">
            <p class="event-details-description capitalize">{{ event.location }}</p>
          </b-col>
        </template>
        <template v-if="event.url != null && event.url.trim().length > 0">
          <b-col cols="1" v-b-tooltip.hover :title="msg('Url')">
            <i class="fas fa-at"></i>
          </b-col>
          <b-col :cols=" event.location != null && event.location.trim().length > 0 ? 5 : 10">
            <b-link :href="event.url" class="event-details-description capitalize">{{ event.url }}</b-link>
          </b-col>
        </template>
      </b-row>

      <div class="mt-2" v-if="hasUsers">
        <!-- Header: User Info -->
        <div class="d-flex my-1">
          <feather-icon icon="UsersIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('Representatives') }}
          </h4>
        </div>
        <hr/>
        <div>
          <div class="event-details-description capitalize">
            <b-avatar-group  >
              <b-avatar  class="pull-up" size="sm" variant="success"
                         v-for="user in getUsers" v-bind:key="user.id"
                         :text="user.acronym"
                         v-b-tooltip :title="user.name"
              />
            </b-avatar-group>
          </div>
        </div>
      </div>


      <div class="" v-if="hasClients">
        <!-- Header: Client Info -->
        <div class="d-flex my-1">
          <i class="fas fa-users" style="font-size: 19px"></i>
          <h4 class="mb-0 ml-50">
            {{ msg('Clients') }}
          </h4>
        </div>
        <hr/>
        <div class="event-details-description capitalize" >
          <b-avatar-group >
            <b-avatar button  class="pull-up" size="sm" variant="primary"
                       v-for="client in clients" v-bind:key="client.id"
                       :text="client.acronym"
                       v-b-tooltip :title="client.name"
                       @click="onClientSelect(client.id)"
            ></b-avatar>
          </b-avatar-group>
        </div>
      </div>
    </template>
    <template v-else-if="event.src != null">
      <div class="row mt-3">
        <div class="col-1">
          <md-icon>calendar_today</md-icon>
        </div>
        <div class="col-11">
          <p class="event-details-description"><a :href="event.src" target="_blank">{{ event.src }}</a></p>
        </div>
      </div>
    </template>
    <!-- Header: Administrative Info -->
    <div class="d-flex my-1" v-if="event.body != null && event.body.trim().length > 0">
      <feather-icon icon="AlignJustifyIcon" size="19"/>
      <h4 class="mb-0 ml-50">
        {{ msg('Description') }}
      </h4>
    </div>
    <hr/>
    <div v-if="event.body != null && event.body.trim().length > 0">
      <p class="event-details-description">{{ event.body }}</p>
    </div>

    <!-- Header: Administrative Info -->
    <div class="d-flex my-1" v-if="event.eventId != null && event.eventId.trim().length > 0">
      <feather-icon icon="AlignJustifyIcon" size="19"/>
      <h4 class="mb-0 ml-50">
        {{ msg('Event ID') }}
      </h4>
    </div>
    <hr/>
    <div v-if="event.eventId != null && event.eventId.trim().length > 0">
      <p class="event-details-description">{{ event.eventId }}</p>
    </div>
  </div>
  <div class="event-details" v-else-if="error !== ''">
    <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i> <strong>{{ error }}</strong>
    </b-alert>
  </div>
  <div class="event-details" v-else>
    Loading
  </div>
</template>

<script>
import { date } from '../../../global/helper'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'calendarEvent',
  data: () => ({
    loaded: false,
    error: '',
    clients: [],
    //Listeners
    listeners: {
      load: () => {
      }
    }
  }),
  created() {
    let $this = this
    this.reloadClients();
  },

  props: {
    event: Object,
    external: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapState({
      data: function (store) {
        return store.data
      }
    }),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    isExternal() {
      return this.external === true
    },
    hasUsers() {
      return this.getUsers.length > 0;
    },
    hasClients() {
      return this.clients.length > 0
    },
    hasOnlyOneClient() {
      return this.hasClients && this.clients.length === 1
    },
    uniqueClient() {
      if (!this.hasOnlyOneClient) {
        return {}
      }
      return this.clients[0]
    },
    getUsers() {
      if (this.event.usersIds == null) {
        return []
      }
      let u = []
      this.event.usersIds.forEach(uId => {
        u.push({id:uId,name:this.getUserFullName(uId),acronym:this.getUserAcronyms(uId)})
      })

      return u
    },
    pageLoaded() {
      return this.event != null && typeof this.event === 'object'
    },
    eventColor() {
      if (this.event == null || this.event.color == null) {
        return '#1867c0'
      }
      return this.event.color
    },
    eventTime() {
      if (this.event == null || this.event.date == null) {
        return 'Date is null'
      }
      return date.dateStringFormated(this.event.date, true)
    }
  },
  methods: {
    ...mapActions('calendar', ['get']),
    ...mapActions('clients', ['getClients']),
    load() {

      let $this = this
      $this.loaded = false
      console.log('load calendar: ' + event.title)
      this.get({ id: $this.event.id })
          .then((event) => {
            if (event == null) {
              return $this.error = 'Load event failed'
            }
            $this.reloadClients();
            $this.loaded = true
          }, (error) => {
            $this.error = error
          })
    },
    reloadClients(){
      let $this = this;
      if (this.event.clientsIds == null || this.event.clientsIds.length <= 0) {
        this.clients = [];
        return
      }
      this.getClients({filter:{ids:this.event.clientsIds}}).then(
          (clientData)=>$this.clients = clientData['clients'].map(client=>{
            let fn = client.firstname != null ? client.firstname.trim() : '';
            let ln = client.lastname != null ? client.lastname.trim() : '';
            let acronym = (fn !== "" ? fn.charAt(0).toUpperCase() : "") + (ln !== "" ? ln.charAt(0).toUpperCase() : "");
            return {id:client.id,name:fn+' '+ln,acronym}
          })
      )
    },
    onClientSelect(id) {
      console.log('CalendarEvent -> onClientSelect', id)
      this.$root.$emit('crm::client::select', id)
    },
  }
}
</script>

<style scoped>

p {
  margin: 0;
}

.event-details .event-details-title {
  text-transform: capitalize;
  line-height: 28px;
  font-family: 'Google Sans', Roboto, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: #3c4043;
}

.event-details .event-details-description {
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 20px;
  color: #3c4043;
}

.event-details .capitalize {
  text-transform: capitalize;

}

.event-details .event-details-description ul {
  margin: 0;
  padding: 0;
}

.event-details .event-details-description ul > li {
  list-style: none;
}
</style>
<style>
.event-details .ps .ps__rail-x,
.event-details .ps .ps__rail-y {
  opacity: 1;
}

.event-details .ps__rail-y > .ps__thumb-y {
  width: 11px;
}

.event-details .ps .ps__rail-y {
  background-color: #eee;
  opacity: 0.9;
}
</style>
