export const string = {
    isEmpty(str) {
        //Check if string is empty
        return str == null || (typeof str === "string" && str.trim().length === 0);
    },
};
export const date = {
    _tzoffset: null,
    getTZOffset(){
        if(this._tzoffset != null)
            return this._tzoffset;
        return this._tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    },
    format() {

    },
    sameMonth(d1, d2) {
        if(d1 == null || d2 == null)
            return false;
        if(typeof d1 != 'object' || typeof d1.getDate != 'function')
            d1 = new Date(d1.toString());
        if(typeof d2 != 'object' || typeof d2.getDate != 'function')
            d2 = new Date(d2.toString());

        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth();
    },
    sameDay(d1, d2) {

        if(typeof d1 != 'object' || typeof d1.getDate != 'function')
            d1 = new Date(d1.toString());
        if(typeof d2 != 'object' || typeof d2.getDate != 'function')
            d2 = new Date(d2.toString());

        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    },
    toDefaultFormat(date,time){
        let d = new Date(date);
        return this._addZero(d.getDate()) + "/" + this._addZero(d.getMonth() +1) + "/" + d.getFullYear().toString().substr(-2) + (time ? " " +this._addZero(d.getHours())+ ":" +this._addZero(d.getHours()) :"")
    },
    dateStringFormated(date,time){
        if (date == null)
            return "";
        let d = new Date(date);
        let lang = localStorage.selectedLang;
        if(lang == null)
            lang = "en";

        let weekDay = d.toLocaleString(lang, {weekday: 'long'});
        let month = d.toLocaleString(lang, {month: 'long'});
        return weekDay + ", " + d.getDate() + " " + month + " " + d.getFullYear() + (time === true ? " " + this._addZero(d.getHours()) + ":" + this._addZero(d.getMinutes()) : "" )
    },
    nowToString(){
      let d = new Date(Date.now() - this.getTZOffset());
      let s = d.toISOString().replace("T"," ");
      s = s.substr(0,s.lastIndexOf(":"));
      return s;
    },
    timeNowToString(){
        let d = new Date(Date.now() - this.getTZOffset());
        let s = d.toISOString();
        s = s.substr(s.lastIndexOf("T")+1);
        s = s.substr(0,s.lastIndexOf(":"));
        return s;
    },
    toString(date){
        let d;
        if(typeof date == "object" && date.getTime != null)
            d = date;
        else if(typeof date == "number")
            d = new Date(d - this.getTZOffset());
        else
            d = new Date(Date.parse(date.toString()) - this.getTZOffset());

        let s = d.toISOString().replace("T"," ");
        s = s.substr(0,s.lastIndexOf(":"));
        return s;
    },
    _addZero(number){
        if(number < 10 && number >= 0 )
            return "0" + number.toString();

        return number
    }
};

export const validation = {
    name(name) {
        if (typeof name !== 'string')
            return false;

        return !(/\d/.test(name));
    }
};

export const requestHelper = {
    arrayToString(array){
       return array.toString().replace('[', '').replace(']', '')
    },
    arrayKeysToString(obj){
        return Object.keys(obj).toString().replace('[', '').replace(']', '')
    }
};

export const number = {
    isNumber(number){
        return !isNaN(number) && typeof number == "number";
    },
    isValid(number){
        return !isNaN(number);
    },
    lessThan(number1,number2){
        if(isNaN(number1) || isNaN(number2))
            return false;
        return parseInt(number1) < parseInt(number2);
    },
    biggerThan(number1,number2){
        if(isNaN(number1) || isNaN(number2))
            return false;
        return parseInt(number1) > parseInt(number2);
    },
    pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }
};
